<template>
  <div class="bodyBg-image">
    <img src="@/assets/img/img3.png" class="img3">
    <my-header></my-header>
    <div class="pr pt40">
      <div class="w1200 box plr25">
        <div class="flex row-between hd">
          <div class="flex">
            <img src="@/assets/img/img4.png">胃镜推荐评估
          </div>
          <div class="flex">
            <div class="pick pickLeft">
              <img src="@/assets/img/pick1.png" class="picks">
              <span>选择胃健康风险评估</span>
            </div>
            <div class="pick pickRight">
              <img src="@/assets/img/pick3.png" class="picks" v-if="type == 1">
              <img src="@/assets/img/pick2.png" class="picks" v-else>
              <span :class="{colr: type == 2}">选择胃健康中医评估</span>
            </div>
          </div>
        </div>
        <div class="list flex flex-wrap">
          <div class="item flex" v-for="(item, index) in list" :key="index" @click="onChange(item)">
            <div class="icons">
              <i class="el-icon-success iconSuccess" v-if="item.id == step1 || item.id == step2"></i>
              <i class="iconError" v-else></i>
            </div>
            <div class="pl15">
              <div class="f16 c333">{{item.type == 1 ? '胃健康风险评估' : item.type == 2 ? '胃健康中医评估' : '胃镜推荐评估'}}</div>
              <div class="f14 c666 pt10">测评时间：{{item.create_time}}</div>
            </div>
          </div>
        </div>
        <el-button type="primary" round class="chooseBtn f20" @click="next">{{type == 1 ? '下一步' : '提交'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'',
  data(){
    return {
      step1: '',
      step2: '',
      type: 1,
      list: [],
      submitBtn: false,
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    }
  },
  mounted(){
    this.getList();
  },
  methods: {
    next(){
      if(this.type == 1){
        if(this.step1 == '') return this.$message.error('请选择胃风险评估');
        this.type = 2;
        this.getList();
      }else{
        if(this.step2 == '') return this.$message.error('请选择胃中医评估');
        this.submit();
      }
    },
    async submit(){
      if(this.submitBtn) return;
      var params = {
        token: this.token,
        zypgid: this.step2,
        fxpgid: this.step1
      }
      var res = await this.$api.post('/index/riskassessment', params);
      if (res.error_code == 0) {
        this.$router.push('/result?isCheck='+res.result+'&id='+res.id+'&img=true');
      }else if (res.error_code == 1) {
        this.$message.error(res.msg);
      }
      this.submitBtn = false;
    },
    async getList(){
      var params = {
        token: this.token,
        limit: 10,
        page: 1,
        type: this.type
      }
      var res = await this.$api.post('/index/historicalrecords', params);
      this.list = res.data;
    },
    onChange(item){
      if(this.type == 1) {
        this.step1 = item.id;
      }else if(this.type == 2){
        this.step2 = item.id;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .pr{
    position: relative;
    z-index: 2;
    .box{
      background-color: #FFFFFF;
      border-radius: 20px;
      height: 720px;
      position: relative;
      .chooseBtn{
        width: 240px;
        height: 50px;
        border-radius: 50px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;
        z-index: 9;
      }

      .list{
        width: 100%;
        margin-top: 24px;
        .item{
          width: 566px;
          height: 84px;
          background: #F5F6F7;
          border-radius: 10px;
          padding-left: 16px;
          margin-bottom: 20px;
          cursor: pointer;
          .icons{
            width: 20px;
          }
          .iconError{
            width: 20px;
            height: 20px;
            border: 1px solid #C0C4CD;
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
          }
          .iconSuccess{
            font-size: 20px;
            color: #857BFE;
          }
          &:nth-of-type(2n){
            margin-left: 18px;
          }
          /deep/ .el-radio{
            margin-right: 0;
          }
        }
      }
      .pick{
        width: 223px;
        height: 48px;
        position: relative;
        
        &:first-child{
          span{
            color: #857BFE;
          }
          .picks{
            left: 6px;
          }
        }
        span{
          font-size: 18px;
          color: #999999;
          position: relative;
          z-index: 2;
          padding-left: 34px;
          line-height: 48px;
          &.colr{
            color: #857BFE;
          }
        }
        .picks{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .hd{
        width: 100%;
        height: 115px;
        border-bottom: 1px solid #EBEBEB;
        img{
          margin-right: 10px;
        }
      }
    }
  }
  .bodyBg-image{
    height: 100vh;
  }
  .img3{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
</style>